/* eslint-disable no-console */
import { register } from "register-service-worker";
import { isMobile } from "./misc";
const isProd = process.env.SKY_APP_ENVIRONMENT === "PROD";

console.log("initilizing service workers...");

if (!("serviceWorker" in navigator)) {
  console.log("This navigator does not support service workers");
}

if (isProd && !isMobile()) {
  register("/sw.js", {
    ready(params) {
      console.log(
        "App is being served from cache by a service worker.\n",
        params
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
