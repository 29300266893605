import { navigateToUrl } from "single-spa";
const COUNTRY_HM_URL = JSON.parse(process.env.HM_LANG_PATH);
import { getIpStackInfo } from "./ip-stack-api";

export const baseUrlRedirect = async () => {
  const retailUrl = new URL(window.location);
  const { pathname } = retailUrl;

  if (pathname !== "/") return;
  const response = await getIpStackInfo();
  const { country } = response;
  if (COUNTRY_HM_URL[country]) {
    navigateToUrl(
      `/${COUNTRY_HM_URL[country].defLang}${COUNTRY_HM_URL[country].url}`
    );
  } else {
    navigateToUrl(`/es/otros`);
  }
};
