const apiUrl = `https://api.ipstack.com/check?access_key=${process.env.IP_STACK_ACCESS_KEY}`;

const _isIpStackInfoStored = () =>
  JSON.parse(
    window.sessionStorage.getItem("geo-localization-client") ?? "false"
  );

export function getIpStackInfo() {
  if (_isIpStackInfoStored() !== false) return _isIpStackInfoStored();
  return new Promise(function (resolve, reject) {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const ipStackInfo = {
          country: data.country_name.toLowerCase().replace(/\s/g, ""),
          country_code: data.country_code.toLowerCase().replace(/\s/g, ""),
          continent_name: data.continent_name.toLowerCase().replace(/\s/g, ""),
          continent_code: data.continent_code,
          currency: data.currency,
          location: data.location,
          time_zone: data.time_zone,
        };
        if (data && data.country_name) {
          const geoClient = {
            message: `Se detecta que la IP:${data.ip} pertenece al país: ${data.country_name}`,
            ...ipStackInfo,
            status: 1,
          };
          const SESSION_KEY = "geo-localization-client";
          const SESSION_VALUE = JSON.stringify(geoClient ?? "{}");
          window.sessionStorage.setItem(SESSION_KEY, SESSION_VALUE);

          resolve(geoClient);
        } else {
          throw new Error(
            "Something was wrong with the data returned by IPStack"
          );
        }
      })
      .catch((error) => {
        const tempErrorResponse = {
          message: `There was an error loading data into IPStack: ${error}, default data is returned`,
          country: "unknown",
          country_code: "unknown",
          continent_code: "SA",
          continent_name: "South America",
          currency: "USD",
          location: "unknown",
          time_zone: new Date().toLocaleString("en-US"),
          status: 0,
          error,
        };
        const SESSION_KEY = "geo-localization-client";
        const SESSION_VALUE = JSON.stringify(tempErrorResponse ?? "{}");
        window.sessionStorage.setItem(SESSION_KEY, SESSION_VALUE);
        resolve(tempErrorResponse);
      });
  });
}
