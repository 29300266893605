import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { getChatbotProps } from "./utils/get-chatbot-props";
import { setCanonicalURL, addAlternateLinks } from "./utils/seoLinks";
import "./utils/register-service-worker";
import { getIpStackInfo } from "./utils/ip-stack-api";
const COUNTRY_HM_URL = JSON.parse(process.env.HM_LANG_PATH);
import { baseUrlRedirect } from "./utils/base-url-redirection-engine";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((a) =>
  registerApplication({
    ...a,
    customProps: {
      flightBoxProps: {
        isTitleComponentShow: false,
        isRetail: true,
        initialSaleUrl: process.env.APP_INITIAL_SALE_URL,
      },
      getIpStackInfo,
      getChatbotProps,
      COUNTRY_HM_URL,
    },
  })
);
layoutEngine.activate();

setCanonicalURL();
addAlternateLinks();
baseUrlRedirect();

start();
